import Vue from 'vue'
import App from './App.vue'

import router from './router'
import i18n from '@/plugins/i18n'
import { store } from '@/store'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import VueMaterial from 'vue-material';
import Toast from "vue-toastification";
import VueEllipseProgress from 'vue-ellipse-progress';
import VueSimpleAlert from "vue-simple-alert";

import moment from "moment";
moment.locale(i18n.locale);

import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;

// css
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import "vue-toastification/dist/index.css";

// init
const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTID,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPID,
    measurementId: process.env.VUE_APP_MEASUREMENTID
}

const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)

Vue.config.productionTip = false;
Vue.prototype.$logEvent = function(message, options = {}) {
    logEvent(analytics, message, options);
};
Vue.use(VueSimpleAlert);
Vue.use(VueEllipseProgress);
Vue.use(VueMaterial);

Vue.component('MdFile', Vue.options.components.MdFile.extend({
    methods: {
        isInvalidValue: function isInvalidValue () {
            return this.$el.validity ? this.$el.validity.badInput : this.$el.querySelector('input').validity.badInput
        }
    }
}))

Vue.use(Toast);

new Vue({
    router,
    i18n,
    store,
    render: h => h(App),
    created() {
        /*if (i18n.locale !== store.getters.locale) {
            store.dispatch('setLocale', i18n.locale);
        }*/
    }
}).$mount('#app')
