<template>
  <div class="container">
    <div class="row pt-3 pl-3 pr-3">
      <div class="col-12">
        <div id="skeleton-form">
          <div class="row">
            <div class="col-12">
              <b-skeleton type="text" animation="wave" width="50%"></b-skeleton>
              <b-skeleton type="input" animation="wave"></b-skeleton>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <b-skeleton type="text" animation="wave" width="60%"></b-skeleton>
              <b-skeleton type="input" animation="wave"></b-skeleton>
            </div>
            <div class="col-6">
              <b-skeleton type="text" animation="wave" width="60%"></b-skeleton>
              <b-skeleton type="input" animation="wave"></b-skeleton>
            </div>
          </div>
        </div>
        <form id="cardForm">
          <div id="payment-element">

          </div>
        </form>
        <div class="justify-content-center mt-4 text-center">
          <md-button class="md-raised md-accent" id="validCardBtn" :disabled="!loaded || processing" @click="process">{{ $t('payments.VALID')}}</md-button>
          <md-progress-spinner  md-mode="indeterminate" v-show="processing" class="mt-2"></md-progress-spinner>
        </div>
        <div class="justify-content-center mt-3 text-center" v-show="loaded">
          <img src="@/assets/img/secured-payment.jpg" alt="" class="d-inline-block mb-2" />
          <p class="text-body" style="font-size: 11px; line-height: normal !important;">{{ $t("payments.SECURE3D_TEXT") }}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import isEmpty from "is-empty";
import { loadStripe } from '@stripe/stripe-js'
import http from "@/utils/http-common";
import paymentConfig from "@/config/payment.config";
//import cryptoUtils from "@/utils/crypto";

let stripe, elements;

export default {
  name: "CardSP",

  props: {
    params: {
      type: String,
      default: () => ""
    },
  },

  data() {
    return {
      loaded: false,
      processing: false,
      defaultValues: {}
    }
  },

  methods: {

    logD(message) {
      if (paymentConfig.config.pp.debug) {
        window.console.log(message);
      }
    },

    logE(error) {
      if (paymentConfig.config.pp.debug) {
        window.console.error(error);
      }
    },

    /*
      Forms methods
     */

    async process() {
      if (this.processing || !this.loaded) {
        return;
      }

      this.processing = true;

      const { error: submitError } = await elements.submit();
      if( submitError ) {
        this.$toast.error(submitError.message);
        this.processing = false;
        return
      }

      let result;
      let clientSecret;
      try {
        const response = await http.post("payments/stripe/process", {
          data: this.params
        });

        result = response.data.result;
        if (isEmpty(result.clientSecret)) {
          this.$toast.error(this.$t("payments.ERROR_PAYMENT_ERROR"));
          this.processing = true;
          return;
        }
        clientSecret = result.clientSecret;
      } catch (error) {
        this.$toast.error(this.$t("payments.ERROR_PAYMENT_ERROR"));
        this.processing = true;
        return;
      }

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              email: result.defaultValues.email,
              name: result.defaultValues.name,
              address: {
                country: result.defaultValues.country
              }
            }
          },
          return_url: `${window.location.origin}/payments/stripe/result`
        }
      });

      if (!isEmpty(error)) {
        if (error.type === "card_error" || error.type === "validation_error") {
          /*this.$toast.error(error.message);*/
          await http.post("payments/stripe/payment-fail", {
            data: this.params,
            error: error
          });
        }
      }
      this.processing = false;
    },
  },

  async mounted() {
    window.console.log("loadStripe");
    stripe = await loadStripe(paymentConfig.config.sp.apiKey);
    let response;
    try {
      response = await http.post("payments/stripe/initialize", {
        data: this.params
      });
    } catch (error) {
      this.$toast.error(error.message);
      return;
    }

    const result = response.data.result;
    const optionsElements = {
      appearance: {
        theme: 'stripe',
        variables: { colorPrimaryText: '#262626' }
      },
      mode: result.paymentType,
      amount: result.amount,
      currency: result.currency
    };

    elements = stripe.elements(optionsElements);
    const optionsCreate = {
      layout: {
        type: 'tabs',
        defaultCollapsed: false,
      },
      business: {
        name: "Spreedl"
      },
      defaultValues: {
        billingDetails: {
          address: {
            country: result.country
          }
        }
      },
      fields: {
        billingDetails: {
          email: 'never',
          address: {
            country: 'never',
          }
        }
      },
    };
    const paymentElement = elements.create('payment', optionsCreate);
    paymentElement.mount("#payment-element");
    paymentElement.on('ready', () => {
      this.loaded = true;
      const skeletonElt = document.getElementById("skeleton-form");
      skeletonElt.remove();
    });
  }
}
</script>

<style scoped>
#validCardBtn {
  width: 100% !important;
  margin: auto !important;
}

#cardForm label {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: #423838;
  width: 100%;
  margin-bottom: 10px !important;
}

#cardForm input[data-error="true"]{
  border-color: #dc3545;
  border-width: 2px;
}

#cardForm div.error {
  color: #dc3545;
}

</style>
