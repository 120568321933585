const paymentConfig = {
    pp: {
        apiKeyId: process.env.VUE_APP_PAYPLUG_APIKEY_ID,
        apiKeyValue: process.env.VUE_APP_PAYPLUG_APIKEY_VALUE,
        tokenizeUrl: process.env.VUE_APP_PAYPLUG_TOKENIZE_URL,
        rsaKeyUrl: process.env.VUE_APP_PAYPLUG_RSAKEY_URL,
        debug: false,
        name: 'pp'
    },
    sp: {
        apiKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
        debug: true,
        name: 'sp'
    },
    hp: {
        username: process.env.VUE_APP_HIPAY_PUBLIC_LOGIN,
        password: process.env.VUE_APP_HIPAY_PUBLIC_PASSWD,
        environment: process.env.VUE_APP_HIPAY_ENVIRONEMENT,
        debug: true,
        name: 'hp'
    },
    available: ["hp"],
};

const _getProviderDefined = (providerId) => {
    if (paymentConfig.available.includes(providerId)) {
        return providerId;
    }
    else {
        return _getProvider();
    }
};

const _getProvider = () => {
    if (paymentConfig.available.length === 1) {
        return paymentConfig.available[0];
    }
    else if (paymentConfig.available.length > 0) {
        let random = Math.floor(Math.random() * paymentConfig.available.length);
        return paymentConfig.available[random];
    }
    else
        return "";
}

export default {

    config: paymentConfig,

    getProviderDefined: _getProviderDefined,

    getProvider: _getProvider
};
