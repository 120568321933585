<template>
  <div>
    <h2>PRÉAMBULE</h2>
    <article class="first">
      La société Pickup Agency AG utilise des cookies dans le cadre du site
      internet spreedl.com (ci-après le "<b>Service</b>").
      <br />La présente charte définit ce que sont les cookies, les différents
      types de cookies utilisés par le Service ainsi que leur gestion.
    </article>
    <h2>1. Qu'est-ce qu'un cookie?</h2>
    <article>
      Un cookie est un petit fichier texte placé par un prestataire de services
      en ligne sur le terminal ou autre dispositif (ordinateurs, smartphone,
      tablettes, etc..) des visiteurs de ses services en ligne par le biais d'un
      navigateur internet. Un cookie a pour but de stocker des données pouvant
      être utilisées à identifier un visiteur, analyser un comportement,
      effectuer des statistiques, mémoriser des préférences utilisateur, assurer
      la bonne éxécution d'une fonctionnalité, etc...
      <br /><br />Un cookie est dit "<strong>persistant</strong>": sa durée de
      vie est fixée par le Service, ou "<strong>de session</strong>": il est
      détruit lors de la fermeture du logiciel de navigation internet.
    </article>
    <h2>2. Les types de cookies utilisés par le Service</h2>
    <article>
      Le Service utilise les différents cookies suivants:
      <br /><br />
      <b>Cookies internes</b>:<br />
      Ces cookies sont nécessaires et obligatoires à l'utilisation du Service.
      Par exemple, ils permettent l'identification du membre et la navigation
      dans l'espace réservé aux membres.
      <br /><br />
      <b>Cookies de fonctionnalité</b>:<br />
      Ces cookies n'empêchent pas la navigation sur le Service, mais ils
      permettent le bon déroulement des différentes fonctionnalités du Service.
      Par exemple des cookies sont utilisés lors de l'utilisation de la
      messagerie.
      <br /><br />
      <b>Cookies d'analyses</b>:<br />
      Ces cookies récoltent des informations <strong>anonymes</strong> à des
      fins d'analyse comportementale d'utilisation du Service. Ces cookies nous
      permettent d'établir des statistiques quant aux habitudes des
      utilisateurs, des terminaux utilisés, etc... <br /><br />
      <b>Cookies de conversion</b>:<br />
      Ces cookies sont des cookies tiers, c'est-à-dire qu'ils sont édités et
      gérés par des services autres que notre Service. Ils permettent
      d'identifier l'origine de l'utilisateur (publicité cliquée, campagne
      emailing, etc...) et d'enregistrer des événements marketing (inscription,
      prise d'abonnement, etc...).
      <br /><br />
      <b>Cookies sociaux</b>:<br />
      Ce sont les cookies utilisés par les programmes fournis par les différents
      réseaux sociaux, par exemple la connexion/inscription par Facebook,
      lecture de nos derniers posts/tweets, etc...
    </article>
    <h2>3. Gestion des cookies</h2>
    <article>
      Vous pouvez autoriser ou refuser (partiellement ou totalement)
      l'enregistrement des cookies sur votre terminal par le biais de votre
      navigateur internet. Ces modifications de paramètrage sont accessibles à
      tout moment et sont prises en compte immédiatement.
      <br /><br />
      Si vous décidez de supprimer ou de refuser (même partiellement) certains
      cookies, l'accès au Service peut se voir dégradé, voire impossible. Vous
      êtes responsable de votre choix de paramètrage et nous déclinons toute
      responsablilité pour les conséquences liées au fonctionnement dégradé du
      Service.
      <br /><br />
      La gestion et le contrôle des cookies est spécifique à chaque navigateur,
      veuillez vous reporter à leur rubrique d'aide afin d'obtenir toutes les
      démarches à effectuer.<br />
    </article>
  </div>
</template>

<script>
export default {
  name: 'CookiesFr'
}
</script>
