import Vue from 'vue'
import VueI18n from 'vue-i18n'
import config from "@/config/config";
//import { store } from "@/store";
// import isEmpty from "is-empty";

Vue.use(VueI18n)

const getStartingLocale = () => {
  /*const oldLocale = store.getters.locale;
  if (!isEmpty(oldLocale) && config.supportedLocales.includes(oldLocale)) {
    return oldLocale;
  }*/

  const browserLocale = getBrowserLocale({ countryCodeOnly: true });
  if (config.supportedLocales.includes(browserLocale)) {
    return browserLocale;
  } else {
    return config.supportedLocales[0];
  }
};

const getBrowserLocale = (options = {}) => {
  const defaultOptions = { countryCodeOnly: false }
  const opt = { ...defaultOptions, ...options }
  const navigatorLocale =
      navigator.languages !== undefined
          ? navigator.languages[0]
          : navigator.language
  if (!navigatorLocale) {
    return undefined
  }
  const trimmedLocale = opt.countryCodeOnly
      ? navigatorLocale.trim().split(/-|_/)[0]
      : navigatorLocale.trim()
  return trimmedLocale
};

const loadLocaleMessages = () => {
  const locales = require.context(
    '../locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: 'fr',
  messages: loadLocaleMessages()
})
